import { TokensData, UserDataFull } from './types';

const storage = {
  storeUser(user: UserDataFull): void {
    console.log('storeUser', new Date().getTime().toString());
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('last_update', new Date().getTime().toString());
  },
  lastUpdate(): Date | null {
    const lastUpdate = localStorage.getItem('last_update');
    return lastUpdate ? new Date(parseInt(lastUpdate)) : null;
  },
  forgetUser(): void {
    localStorage.removeItem('user');
  },
  getUser(): UserDataFull | null {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  },

  storeTokens(data: TokensData): void {
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    localStorage.setItem('access_expires', data.access_expires);
    localStorage.setItem('refresh_expires', data.refresh_expires);
  },

  forgetTokens(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_expires');
    localStorage.removeItem('refresh_expires');
  },

  getTokens(): TokensData {
    return {
      access_token: localStorage.getItem('access_token') as string,
      refresh_token: localStorage.getItem('refresh_token') as string,
      access_expires: localStorage.getItem('access_expires') as string,
      refresh_expires: localStorage.getItem('refresh_expires') as string,
    };
  },

  getAccessToken(): string | null {
    return localStorage.getItem('access_token');
  },

  getRefreshToken(): string | null {
    return localStorage.getItem('refresh_token');
  },

  getAccessTokenExpires(): Date | null {
    const date = localStorage.getItem('access_expires');

    return date ? new Date(date) : null;
  },

  getRefreshTokenExpires(): Date | null {
    const date = localStorage.getItem('refresh_expires');

    return date ? new Date(date) : null;
  },

  getValidAccessToken(): string | null {
    const expires = this.getAccessTokenExpires();
    if (!expires || expires < new Date()) {
      return null;
    }
    return this.getAccessToken();
  },

  getValidRefreshToken(): string | null {
    const expires = this.getRefreshTokenExpires();
    if (!expires || expires < new Date()) {
      return null;
    }
    return this.getRefreshToken();
  },
};

export default storage;
