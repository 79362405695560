import React, { createContext, useContext, ReactNode } from 'react';
import featuresConfig from '../features.json';

export interface FeatureFlagsContextProps {
  is: (feature: FeatureFlags) => boolean;
}

interface FeatureFlagsProviderProps {
  children: ReactNode;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextProps | undefined>(undefined);

export enum FeatureFlags {
  REPORT_PLAYER_IN_GAME_RESULT = 'report_player_in_game_result',
  REPORT_PLAYER_IN_GAME_REPLAY = 'report_player_in_game_replay',
  WALLET_PAGE = 'wallet_page',
  WALLET_CONNECT = 'wallet_connect',
  WALLET_DEPOSIT = 'wallet_deposit',
  WALLET_WITHDRAW_MAIN = 'wallet_withdraw_main',
  WALLET_TRANSFER = 'wallet_transfer',
}

// https://dev.to/thesanjeevsharma/adding-feature-flags-to-your-react-codebase-22a8
export const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({ children }) => {
  const [features, setFeatures] =
    React.useState<Partial<{ [key in FeatureFlags]: boolean }>>(featuresConfig);

  const isEnabled = (feature: FeatureFlags) => features[feature] ?? false;

  return (
    <FeatureFlagsContext.Provider value={{ is: isEnabled }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = (): FeatureFlagsContextProps => {
  const context = useContext(FeatureFlagsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

export const useFeatureFlag = (feature: FeatureFlags): boolean => {
  const { is } = useFeatureFlags();
  return is(feature);
};
