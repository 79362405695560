import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { userAPI } from '@api/userAPI';
import { gamesAPI } from '@api/gamesAPI';
import BlockLoader from '@components/BlockLoader';

type UsersStats = {
  total_count: number;
  online_count: number;
};
type GamesStats = {
  total_count: number;
  active_count: number;
};

const HeadBanner = () => {
  const { t } = useTranslation();

  const {
    isLoading: isUsersLoading,
    error: usersLoadError,
    data: usersStatsData,
    refetch: refetchUsersStats,
  }: {
    isLoading: boolean;
    error: string | null;
    data: UsersStats | undefined;
    refetch: () => void;
  } = useQuery({
    queryKey: ['usersStats'],
    queryFn: () => userAPI.stats(),
    cacheTime: 60000,
    staleTime: 60000,
  });

  const {
    isLoading: isGamesLoading,
    error: gamesLoadError,
    data: gamesStatsData,
    refetch: refetchGamesStats,
  }: {
    isLoading: boolean;
    error: string | null;
    data: GamesStats | undefined;
    refetch: () => void;
  } = useQuery({
    queryKey: ['gamesStats'],
    queryFn: () => gamesAPI.stats(),
    cacheTime: 60000,
    staleTime: 60000,
  });

  return (
    <div className="section banner">
      <div className="in">
        <div className="left">
          <div className="stats mb-2">
            <span className="label">{t('home.registered-users')}</span>
            <div className="info-box">
              <div className="value">
                {isUsersLoading ? <BlockLoader /> : usersStatsData?.total_count}
              </div>
            </div>
          </div>

          <div className="stats">
            <span className="label">{t('home.players-online')}</span>
            <div className="info-box">
              <div className="value">
                {isUsersLoading ? <BlockLoader /> : usersStatsData?.online_count}
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="stats mb-2">
            <span className="label">{t('home.total-games')}</span>
            <div className="info-box">
              <div className="value">
                {isGamesLoading ? <BlockLoader /> : gamesStatsData?.total_count}
              </div>
            </div>
          </div>

          <div className="stats">
            <span className="label">{t('home.games-online')}</span>
            <div className="info-box">
              <div className="value">
                {isGamesLoading ? <BlockLoader /> : gamesStatsData?.active_count}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadBanner;
