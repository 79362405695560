import { useLocation, Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuth } from '@contexts/AuthContext';
import Loader from './Loader';
import TagManager from 'react-gtm-module';

const RequireAuth = () => {
  const location = useLocation();
  const { currentUser, isRefreshing } = useAuth();

  // const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  console.log('Search params:', searchParams.toString());

  if (currentUser) {
    TagManager.dataLayer({
      dataLayer: {
        userId: currentUser.id,
      },
      dataLayerName: 'UserDataLayer',
    });

    if (!currentUser.email_verified) {
      return <Navigate to={'/registration/verify'} state={{ from: location }} replace />;
    }

    return isRefreshing ? <Loader /> : <Outlet />;
  }

  const ref = searchParams.get('ref');

  return localStorage.getItem('isWelcomeScreenSeen') ? (
    <Navigate to={'/login'} state={{ from: location, ref: ref }} replace />
  ) : (
    <Navigate to={'/presentation'} state={{ from: location, ref: ref }} replace />
  );
};

export default observer(RequireAuth);
