import { Swiper, SwiperProps } from 'swiper/react';
import { FreeMode, Mousewheel, Navigation } from 'swiper/modules';
import { FC, PropsWithChildren } from 'react';
import 'swiper/css/navigation';

export const CustomSwiper: FC<PropsWithChildren<SwiperProps>> = ({ children, ...swiperProps }) => {
  return (
    <Swiper
      freeMode={true}
      grabCursor={true}
      mousewheel={true}
      navigation={true}
      modules={[Mousewheel, FreeMode, Navigation]}
      breakpoints={{
        0: {
          slidesPerView: 2,
        },
        240: {
          slidesPerView: 3,
        },
        360: {
          slidesPerView: 4,
        },
        480: {
          slidesPerView: 5,
        },
        640: {
          slidesPerView: 6,
        },
      }}
      {...swiperProps}>
      {children}
    </Swiper>
  );
};
