import classNames from 'classnames';
import { t } from 'i18next';

type Props = {
  timeVariant: number;
  selectedTime: number | null;
  onSelectTime: (time: number) => void;
};

function Time({ timeVariant, selectedTime, onSelectTime }: Props) {
  return (
    <button
      onClick={() => onSelectTime(timeVariant)}
      className={classNames('slide-container bot-time-control', {
        active: selectedTime === timeVariant,
      })}>
      <span className="name">
        {timeVariant} {t('home.bot.time-mins')}
      </span>
    </button>
  );
}

export default Time;
