import { GameMode } from '@pages/Game/common/modes';
import classNames from 'classnames';
import { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useModal } from '@pages/Game/contexts/ModalManagerContext';

type Props = {
  mode: GameMode;
  fallback: () => void;
};

const ModeCard: FC<Props> = ({ mode, fallback }) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { link, modal, icon, titleKey, descriptionKey, isMuted } = mode;

  const content = useMemo(
    () => (
      <div className={classNames('game-mode', { muted: isMuted })}>
        <div className="mode-icon">
          <i className={icon}></i>
        </div>
        <h2>{t(titleKey)}</h2>
        <p>{t(descriptionKey)}</p>
      </div>
    ),
    [icon, isMuted, titleKey, descriptionKey, t],
  );

  return (
    <div className="col-6 mb-2 mode-card">
      {link ? (
        <Link to={link}>{content}</Link>
      ) : modal ? (
        <>
          <button onClick={() => openModal(modal)}>{content}</button>
        </>
      ) : (
        <button onClick={fallback}>{content}</button>
      )}
    </div>
  );
};

export default ModeCard;
