import useLocalStorage from '@hooks/useLocalStorage';
import { PieceStyle } from '@libs/types';
import React, { createContext, PropsWithChildren, useCallback, useContext } from 'react';

type Theme = {
  pieceStyle: PieceStyle;
  boardColor: string;
  animSpeed: number;
};
interface UserBoard {
  theme: Theme;
  setThemeProps(props: Partial<Theme>): void;
  setAnimationSpeed(speed: number): void;
}

const defaultTheme = {
  pieceStyle: 'staunty' as PieceStyle,
  boardColor: 'standard',
  animSpeed: 200,
};

const UserBoardContext = createContext<UserBoard>({
  theme: defaultTheme,
  setThemeProps: () => {},
  setAnimationSpeed: () => {},
});

export const UserBoardProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useLocalStorage('user-board-settings', defaultTheme);

  const setThemeProps = (props: Theme) => {
    setTheme((prev) => ({ ...prev, ...props }));
  };

  const setAnimationSpeed = useCallback((speed: number) => {
    setTheme((prev) => ({ ...prev, animSpeed: speed }));
  }, []);

  const value: UserBoard = {
    theme,
    setThemeProps,
    setAnimationSpeed,
  };

  return <UserBoardContext.Provider value={value}>{children}</UserBoardContext.Provider>;
};

export const useUserBoard = (): UserBoard => {
  const context = useContext(UserBoardContext);
  if (context === undefined) {
    throw new Error('useUserBoard must be used within a UserBoardProvider');
  }
  return context;
};
