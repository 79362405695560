import en from '../locales/en.json';
import uk from '../locales/uk.json';
import ru from '../locales/ru.json';
import es from '../locales/es.json';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

//export const defaultNS = 'en'
export const resources = {
  en: {
    translation: en,
  },
  uk: {
    translation: uk,
  },
  ru: {
    translation: ru,
  },
  es: {
    translation: es,
  },
} as const;

const supportedLangs = ['en', 'uk', 'es', 'ru'];
export const DEFAULT_LANG = 'en';

let lang = localStorage.getItem('lang');
if (!lang || !supportedLangs.includes(lang)) {
  lang = navigator.language.slice(0, 2).toLowerCase();
  if (!supportedLangs.includes(lang)) {
    lang = DEFAULT_LANG;
  }
  localStorage.setItem('lang', lang);
}

i18n.use(initReactI18next).init({
  lng: lang,
  fallbackLng: DEFAULT_LANG,
  debug: false,
  supportedLngs: supportedLangs,
  // defaultNS,
  resources,
});

export default i18n;
