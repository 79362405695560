import { IonIcon } from '@ionic/react';
import {
  personOutline,
  peopleOutline,
  playOutline,
  walletOutline,
  podiumOutline,
} from 'ionicons/icons';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';

type MenuItemProps = {
  to: string;
  icon: string;
  label?: string;
};

const MenuItem = ({ to, icon, label }: MenuItemProps) => (
  <NavLink to={to} className={({ isActive }) => (isActive ? 'item active' : 'item')}>
    <div className="col">
      {label && <IonIcon icon={icon} />}
      {label && <strong>{t(label)}</strong>}
      {!label && (
        <div className="action-button">
          <IonIcon icon={icon} />
        </div>
      )}
    </div>
  </NavLink>
);

export const BottomMenu = () => {
  return (
    <div className="appBottomMenu">
      <MenuItem to="/profile" icon={personOutline} label="bottom-menu.profile" />
      <MenuItem to="/team" icon={peopleOutline} label="bottom-menu.team" />
      <MenuItem to="/" icon={playOutline} />
      <MenuItem to="/leaderboard" icon={podiumOutline} label="bottom-menu.leaderboard" />
      <MenuItem to="/wallet" icon={walletOutline} label="bottom-menu.wallet" />
    </div>
  );
};
