import React, { createContext, useContext, ReactNode } from 'react';

interface PlaySoundOptions {
  volume?: number;
}

interface AudioContextProps {
  playSound: (url: string, options?: PlaySoundOptions) => void;
}

const AudioContext = createContext<AudioContextProps | undefined>(undefined);

interface AudioProviderProps {
  children: ReactNode;
}

const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
  const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

  const playSound = async (url: string, options: PlaySoundOptions = {}) => {
    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const source = audioContext.createBufferSource();
      const gainNode = audioContext.createGain();

      source.buffer = audioBuffer;
      source.connect(gainNode);
      gainNode.connect(audioContext.destination);

      if (options.volume !== undefined) {
        gainNode.gain.value = options.volume;
      }

      source.start(0);
    } catch (error) {
      console.error('Error playing sound:', error);
    }
  };

  return <AudioContext.Provider value={{ playSound }}>{children}</AudioContext.Provider>;
};

export const useAudio = (): AudioContextProps => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }
  return context;
};

export default AudioProvider;
