import React, { useState, useCallback, useMemo, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import classNames from 'classnames';

interface RatingRangePickerProps {
  minRating: number;
  maxRating: number;
  step: number;
  onChange: (from: number, to: number) => void;
}

const RatingRange: React.FC<RatingRangePickerProps> = ({
  minRating,
  maxRating,
  step,
  onChange
}) => {
  const ratingOptions = useMemo(() => 
    Array.from(
      { length: Math.floor((maxRating - minRating) / step) + 1 },
      (_, i) => minRating + i * step
    ),
    [minRating, maxRating, step]
  );

  const [fromValue, setFromValue] = useState(minRating);
  const [toValue, setToValue] = useState(maxRating);

  const carouselOptions: EmblaOptionsType = {
    axis: 'y',
    loop: false,
    dragFree: true,
    duration: 30,
    containScroll: false,
    watchSlides: false
  };

  const [fromViewportRef, fromEmbla] = useEmblaCarousel({...carouselOptions, startIndex: 0});
  const [toViewportRef, toEmbla] = useEmblaCarousel({...carouselOptions, startIndex:ratingOptions.length -1 });

  const onSelect = useCallback((embla: EmblaCarouselType | undefined, setValue: React.Dispatch<React.SetStateAction<number>>) => {
    if (embla) {
      const selectedIndex = embla.selectedScrollSnap();
      const newValue = ratingOptions[selectedIndex];
      setValue(newValue);
    }
  }, [ratingOptions]);

  const scrollToCenter = useCallback((embla: EmblaCarouselType | undefined) => {
    if (embla) {
      const selectedIndex = embla.selectedScrollSnap();
      embla.scrollTo(selectedIndex, true);
    }
    
  }, []);

  const onFromSelect = useCallback(() => {
    onSelect(fromEmbla, setFromValue)
    scrollToCenter(fromEmbla);
  }, [fromEmbla, onSelect]);

  const onToSelect = useCallback(() => {
    onSelect(toEmbla, setToValue)
    scrollToCenter(toEmbla);
  }, [toEmbla, onSelect]);

  useEffect(() => {
    if (fromEmbla) {
      fromEmbla.on('select', onFromSelect);
      fromEmbla.on('settle', () => onChange(fromValue, toValue));
    }
    if (toEmbla) {
      toEmbla.on('select', onToSelect);
      toEmbla.on('settle', () => onChange(fromValue, toValue));
    }
    return () => {
      if (fromEmbla) {
        fromEmbla.off('select', onFromSelect);
      }
      if (toEmbla){
        toEmbla.off('select', onToSelect);
      } 
    };
  }, [fromEmbla, toEmbla, onFromSelect, onToSelect, onChange, fromValue, toValue]);

  const renderPickerItem = useCallback((value: number, embla: EmblaCarouselType | undefined) => {
    const selected = (embla?.selectedScrollSnap() === ratingOptions.indexOf(value));
    return (
      <div className={classNames('rating-item', { 'selected': selected })}>
        {value}
      </div>
    );
  }, [ratingOptions]);

  return (
    <div className="rating-range-picker">
      <div className="rating-pickers-container">
        <div className="rating-picker rating-picker-from" ref={fromViewportRef}>
          <div className="rating-picker-items">
            {ratingOptions.map((value) => (
              <div key={value} className="rating-picker-slide">
                {renderPickerItem(value, fromEmbla)}
              </div>
            ))}
          </div>
        </div>
        <div className="rating-picker rating-picker-to" ref={toViewportRef}>
          <div className="rating-picker-items">
            {ratingOptions.map((value) => (
              <div key={value} className="rating-picker-slide">
                {renderPickerItem(value, toEmbla)}
              </div>
            ))}
          </div>
          <div className="rating-selection-line"></div>
        </div>
      </div>
    </div>
  );
};

export default RatingRange;