import { TokensData } from '@libs/types';
import { instanceAnonymous, instance } from '@api/axios';

export const authAPI = {
  authPromise: null as Promise<any> | null,

  login(data: any): Promise<TokensData> {
    return instanceAnonymous
      .post('auth/login', JSON.stringify(data))
      .then((response) => response.data);
  },
  logout(refresh_token: string) {
    return instance
      .post(
        'auth/logout',
        JSON.stringify({
          refresh_token: refresh_token,
        }),
      )
      .then((response) => response.data);
  },
  logoutAll() {
    return instance.post('auth/logout-all').then((response) => response.data);
  },
  register(data: any): Promise<TokensData> {
    const { email, password, name, language_code } = data;

    return instanceAnonymous
      .post(
        'auth/register',
        JSON.stringify({
          email,
          password,
          name,
          language_code,
        }),
      )
      .then((response) => response.data);
  },
  refresh(refresh_token: string): Promise<TokensData> {
    return instanceAnonymous
      .post('auth/refresh', { refresh_token })
      .then((response) => response.data);
  },
  authViaTelegram(telegram: any, language_code: string): Promise<any> {
    if (this.authPromise) {
      return this.authPromise;
    }

    this.authPromise = instanceAnonymous
      .post(
        'auth/auth/telegram',
        JSON.stringify({
          init_data: telegram.initData,
          language_code: language_code,
        }),
      )
      .then((response) => response.data)
      .finally(() => {
        this.authPromise = null;
      });

    return this.authPromise;
  },
  authViaToken(token: string): Promise<TokensData> {
    return instanceAnonymous
      .post(
        'auth/token',
        JSON.stringify({
          token,
        }),
      )
      .then((response) => response.data);
  },
};
