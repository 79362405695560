// import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

export default function init() {
  // https://www.npmjs.com/package/react-gtm-module
  const GTAG_TRACKING_ID = import.meta.env.VITE_GTAG_TRACKING_ID;
  if (GTAG_TRACKING_ID) {
    TagManager.initialize({
      gtmId: GTAG_TRACKING_ID,
      // dataLayer: {
      //   userId: '001',
      //   userProject: 'project',
      // },
    });
  }

  // const TRACKING_ID = import.meta.env.VITE_GA_TRACKING_ID;
  // if (TRACKING_ID) {
  //   ReactGA.initialize([
  //     {
  //       trackingId: TRACKING_ID,
  //       gaOptions: {
  //         // userId: 1,
  //       }, // optional
  //       gtagOptions: {

  //       }, // optional
  //     },
  //   ]);
  // }

  // Send pageview with a custom path
  // ReactGA.send({ hitType: 'pageview', page: '/my-path', title: 'Custom Title' });

  // // Send a custom event
  // ReactGA.event({
  //   category: 'your category',
  //   action: 'your action',
  //   label: 'your label', // optional
  //   value: 99, // optional, must be a number
  //   nonInteraction: true, // optional, true/false
  //   transport: 'xhr', // optional, beacon/xhr/image
  // });
}
