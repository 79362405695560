import { ReactNode } from 'react';
import Logo from '@components/Logo';
import { Link } from 'react-router-dom';

type Props = {
  leftBtn?: ReactNode;
  rightBtn?: ReactNode;
  title?: string;
};

export const PageHeader = ({ leftBtn, rightBtn, title }: Props) => {
  return (
    <div className="appHeader">
      {leftBtn && <div className="left">{leftBtn}</div>}
      <div className="pageTitle">
        {title || (
          <Link to="/">
            <Logo />
          </Link>
        )}
      </div>
      {rightBtn && <div className="right">{rightBtn}</div>}
    </div>
  );
};
