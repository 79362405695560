import { FC } from 'react';
import ModeCard from './ModeCard';
import { MODES_LIST } from '@pages/Game/common/modes';

type Props = {
  // setShow: Dispatch<SetStateAction<boolean>>;
  fallback: () => void;
};

const ModeCardList: FC<Props> = ({ fallback }) => {
  return (
    <div className="section mt-20">
      <div className="row">
        {MODES_LIST.map((mode, index) => (
          <ModeCard key={index} mode={mode} fallback={fallback} />
        ))}
      </div>
    </div>
  );
};

export default ModeCardList;
