import NavbarLogo from '@assets/img/logos/logo-orange.svg';

const Logo = () => {
  return (
    <>
      <img src={NavbarLogo} alt="logo" className="logo" />
      <span className="logo">ChessNode</span>
    </>
  );
};

export default Logo;
