import { PropsWithChildren } from 'react';

const ModalBodyWrapper = ({ children }: PropsWithChildren) => {
  return (
    <div className="action-sheet-content">
      <div className="app-box card">
        <div className="card-body text-center">{children}</div>
      </div>
    </div>
  );
};

export default ModalBodyWrapper;
