const BlockLoader = () => {
  return (
    <div className="block-loader linear-loader">
      <span className="item-1"></span>
      <span className="item-2"></span>
      <span className="item-3"></span>
      <span className="item-4"></span>
      <span className="item-5"></span>
      <span className="item-6"></span>
    </div>
  );
};

export default BlockLoader;
