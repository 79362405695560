import { ModalName } from '@pages/Game/contexts/ModalManagerContext';
import { Dispatch, SetStateAction } from 'react';

export type ModalProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
};

export type GameMode = {
  modal?: ModalName;
  link?: string;
  icon: string;
  titleKey: string;
  descriptionKey: string;
  isMuted: boolean;
};

const IS_ENABLED_PLAY_WITH_BOT = import.meta.env.VITE_IS_ENABLED_PLAY_WITH_BOT === 'true' || false;
const IS_ENABLED_CHALLENGE = import.meta.env.VITE_IS_ENABLED_CHALLENGE === 'true' || false;

export const MODES_LIST: GameMode[] = [
  {
    modal: ModalName.MINING_TIME_FRAME,
    icon: 'fa-solid fa-chess fa-3x',
    titleKey: 'home.mining.title',
    descriptionKey: 'home.mining.description',
    isMuted: false,
  },
  {
    modal: IS_ENABLED_PLAY_WITH_BOT ? ModalName.PLAY_WITH_BOT : undefined,
    icon: 'fa-solid fa-robot fa-3x',
    titleKey: 'home.bot.title',
    descriptionKey: 'home.bot.description',
    isMuted: !IS_ENABLED_PLAY_WITH_BOT,
  },
  {
    modal: IS_ENABLED_CHALLENGE ? ModalName.CHALLENGE : undefined,
    icon: 'fa-solid fa-chess-knight fa-3x',
    titleKey: 'home.challenge.title',
    descriptionKey: 'home.challenge.description',
    isMuted: !IS_ENABLED_CHALLENGE,
  },
  /* {
    onClick: () => setShow(true),
    link: '#',
    icon: 'fa-solid fa-graduation-cap fa-3x',
    titleKey: 'home.practice.title',
    descriptionKey: 'home.practice.description',
    isMuted: true,
  }, */
  {
    icon: 'fa-solid fa-trophy fa-3x',
    titleKey: 'home.tournament.title',
    descriptionKey: 'home.tournament.description',
    isMuted: true,
  },
];

// type GameMode = {
//   ident: string;
//   label: string;
// };

export type MiningGameModeIdent = '1m' | '3m' | '5m' | '10m';

export type MiningGameMode = {
  ident: MiningGameModeIdent;
  labelKey: string;
  descKey: string;
  host: string;
  link: string;
};

export const MINING_MODE_LIST: MiningGameMode[] = [
  {
    ident: '1m',
    labelKey: 'home.mining.timeframes.1m',
    descKey: 'home.mining.timeframes.1m-description',
    host: import.meta.env.VITE_GAME_1M_URL as string,
    link: '/game/mining/1m',
  },
  {
    ident: '3m',
    labelKey: 'home.mining.timeframes.3m',
    descKey: 'home.mining.timeframes.3m-description',
    host: import.meta.env.VITE_GAME_3M_URL as string,
    link: '/game/mining/3m',
  },
  {
    ident: '5m',
    labelKey: 'home.mining.timeframes.5m',
    descKey: 'home.mining.timeframes.5m-description',
    host: import.meta.env.VITE_GAME_5M_URL as string,
    link: '/game/mining/5m',
  },
  {
    ident: '10m',
    labelKey: 'home.mining.timeframes.10m',
    descKey: 'home.mining.timeframes.10m-description',
    host: import.meta.env.VITE_GAME_10M_URL as string,
    link: '/game/mining/10m',
  },
];
