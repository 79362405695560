import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';

import CloseButton from '@components/buttons/CloseButton';
import ModalBodyWrapper from '@components/wrappers/ModalBodyWrapper';

type Props = {
  show: boolean;
  setShow: (value: boolean) => void;
  text?: string | null;
  title: string;
  children: JSX.Element;
};

const BaseModal = ({ show, setShow, text = null, title, children }: Props) => {
  return (
    <Modal className="modal action-sheet" show={show} onHide={() => setShow(false)}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <ModalHeader>
            <h5 className="modal-title">{title}</h5>
            <div className="right">
              <CloseButton onHide={() => setShow(false)} />
            </div>
          </ModalHeader>
          <ModalBody>
            <ModalBodyWrapper>
              {text && <p className="mb-2">{text}</p>}

              {children}
            </ModalBodyWrapper>
          </ModalBody>
        </div>
      </div>
    </Modal>
  );
};

export default BaseModal;
