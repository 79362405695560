import ReactDOM from 'react-dom/client';
import App from './components/App';
import './libs/i18n';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './index.css';

import 'swiper/css';
import 'swiper/css/pagination';
import { initSentry } from './sentry';
import initAnalytics from './analytics';

initSentry();
initAnalytics();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
