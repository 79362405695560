import { ChainInfo } from "../types";

export const WRONG_NETWORK_ID = 'wrong_network';

export const CHAIN_NAME = 'whitechain_testnet';

export const chainNames: Record<string, string> = {
  // "0x89": "Polygon",
  // "0x753": "Whitechain",
  "0xa41": "Whitechain Testnet",
};

export const chainConfigs: Record<string, ChainInfo> = {
  // 'polygon': {
  //   chainId: "0x89",
  //   chainName: "Polygon Mainnet",
  //   blockExplorerUrls: ["https://polygonscan.com"],
  //   nativeCurrency: { symbol: "POL", decimals: 18 },
  //   rpcUrls: ["https://polygon-rpc.com/"],
  // },
  // 'whitechain': {
  //   chainId: "0x753",
  //   chainName: "Whitechain",
  //   blockExplorerUrls: ["https://explorer.whitechain.io"],
  //   nativeCurrency: { symbol: "WBT", decimals: 18 },
  //   rpcUrls: ["https://rpc.whitechain.io"],
  // },
  'whitechain_testnet': {
    chainId: "0xa41",
    chainName: "Whitechain Testnet",
    blockExplorerUrls: ["https://testnet.whitechain.io"],
    nativeCurrency: { symbol: "WBT", decimals: 18 },
    rpcUrls: ["https://rpc-testnet.whitechain.io"],
  },
};
