import * as Sentry from '@sentry/react';
import { Routes } from 'react-router-dom';

const initSentry = () => {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      ignoreErrors: ['NotAllowedError'],
    });
  }
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export { initSentry, SentryRoutes };
