import loadingIcon from '@assets/img/logos/loader-logo.svg';

const Loader = () => {
  return (
    <div id="loader">
      <img src={loadingIcon} alt="icon" className="loading-icon" />
    </div>
  );
};

export default Loader;
