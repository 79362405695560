import React, { createContext, useContext, PropsWithChildren } from 'react';

interface SettingsContextType {
  isAllowRegistration: boolean;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

const getEnv = (envVar: string): boolean => {
  return import.meta.env[envVar] === 'true';
};

export const SettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const state: SettingsContextType = {
    isAllowRegistration:
      getEnv('VITE_ALLOW_REGISTRATION') ||
      // TODO: Remove this line after testing
      new URLSearchParams(window.location.search).get('is_test') === 'true',
  };

  return <SettingsContext.Provider value={state}>{children}</SettingsContext.Provider>;
};

export const useSettings = (): SettingsContextType => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};
