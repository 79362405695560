import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import ContextProvider from './ContextProvider';
import { Router } from '@router';

if (window.Telegram?.WebApp) {
  window.Telegram.WebApp.ready();
  window.Telegram.WebApp.setHeaderColor('#202020');
  window.Telegram.WebApp.enableClosingConfirmation();
  window.Telegram.WebApp.expand();

  window.Telegram.WebApp.BackButton.show();
  window.Telegram.WebApp.BackButton.onClick(() => window.history.back());
}

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ContextProvider>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/*" element={<Router />} />
          </Routes>
        </QueryClientProvider>
      </ContextProvider>
    </BrowserRouter>
  );
};

export default App;
